




import { AmortizationForm } from "@/components/Amortization";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CreatePage",
  components: {
    AmortizationForm,
  },
})
export default class EditPage extends Vue {
  @Prop({ required: true, type: String })
  readonly id!: string;
}
